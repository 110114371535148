import logo from './logo.svg';
import './App.css';
import image from './Gm.png'
function App() {
  return (
    //here we start coding website
    <div className="imageParent">
    {/* <img src={image} className="image"/> */}
    </ div>
  )
}

export default App;